import './custom.css';
import MainLayout from './components/telegramApp/layout/MainLayout';

function App() {
    var productionMode = true;

    if (productionMode && window.Telegram.WebApp.isVersionAtLeast('8.0') && window.Telegram.WebApp.requestFullscreen) {
        window.Telegram.WebApp.requestFullscreen();
    }

    return (
        <MainLayout productionMode={productionMode} />
        )
}
export default App;