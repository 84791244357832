import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account } from '../../store/Models';
import { formatCost, getClassSingle, makeDateForCharity } from '../../utils';
import AccountAvatar from './AccountAvatar';

interface CharityChallengeTableViewProps {
    currentUser: Account;
    viewMode: number;
    onAccountClick: (account: Account) => void;
}

interface CharityChallenge {
    account: Account;
    value: number;
    currency: string;
    usdValue: number;
}

interface CharityChallengeTotal {
    account: Account;
    continuousWeeks: number;
    totalSum: number;
}

interface CharityDay {
    charityDate: string;
    week: number;
}

interface CharityDateData {
    charityDate: string;
    week: number;
    data: CharityChallenge[];
}

const CharityChallengeTableView: React.FC<CharityChallengeTableViewProps> = ({ currentUser, viewMode, onAccountClick }) => {
    const fetch = useFetch();

    const charityDataRef = React.useRef<CharityDateData[]>([]);

    const [charityChallengeFullView, setCharityChallengeFullView] = React.useState<boolean>(false);

    const [charityChallengeData, setCharityChallengeData] = React.useState<CharityChallenge[]>([]);
    const [charityChallengeTotalData, setCharityChallengeTotalData] = React.useState<CharityChallengeTotal[]>([]);

    const [charityDays, setCharityDays] = React.useState<CharityDay[]>([]);
    const [currentCharityDate, setCurrentCharityDate] = React.useState<CharityDay | null>(null);

    const [loadingState, setLoadingState] = React.useState<boolean>(false);

    const [totalSortColumn, setTotalSortColumn] = React.useState<string>('cw');

    React.useEffect(() => {
        console.log('totalSortColumn: ' + totalSortColumn);
        if (totalSortColumn === 'cw') {
            setCharityChallengeTotalData((x) => [...x].sort((a, b) => { return b.continuousWeeks - a.continuousWeeks }));
        }
        else if (totalSortColumn === 'ts') {
            setCharityChallengeTotalData((x) => [...x].sort((a, b) => { return b.totalSum - a.totalSum }));
        }
    }, [totalSortColumn]);

    React.useEffect(() => {
        setLoadingState(true);
        fetch('/api/challenge/getCharityDays')
            .then(response => response.json())
            .then(data => {
                setCharityDays(data);
                setCurrentCharityDate(data[data.length-1]);
            });
        fetch('/api/challenge/getCharityChallengeTotalResults')
            .then(response => response.json() as Promise<CharityChallengeTotal[]>)
            .then(data => {
                setCharityChallengeTotalData(data);
            });
    }, []);

    React.useEffect(() => {
        if (currentCharityDate != null) {
            if (charityDataRef.current.filter(c => c.week == currentCharityDate.week).length == 0) {
                setLoadingState(true);
                fetch('/api/challenge/getCharityChallengeResultsOnWeek?week=' + currentCharityDate.week)
                    .then(response => response.json() as Promise<CharityChallenge[]>)
                    .then(data => {
                        setCharityChallengeData(data);
                        charityDataRef.current.push({
                            charityDate: currentCharityDate.charityDate,
                            week: currentCharityDate.week,
                            data: data
                        });
                    })
                    .finally(() => setLoadingState(false));
            }
            else {
                setCharityChallengeData(charityDataRef.current.filter(c => c.week == currentCharityDate.week)[0].data);
            }
        }
    }, [currentCharityDate]);

    React.useEffect(() => {
        setCharityChallengeFullView(false);
    }, [viewMode]);

    const switchToBeginning = () => {
        setCharityChallengeData([]);
        setCharityChallengeFullView(false);
        setCurrentCharityDate(charityDays[0]);
    }

    const switchToPrev = () => {
        if (currentCharityDate != null) {
            setCharityChallengeData([]);
            setCharityChallengeFullView(false);
            setCurrentCharityDate(charityDays.filter(c => c.week == currentCharityDate.week - 1)[0]);
        }
    }

    const switchToNext = () => {
        if (currentCharityDate != null) {
            setCharityChallengeData([]);
            setCharityChallengeFullView(false);
            setCurrentCharityDate(charityDays.filter(c => c.week == currentCharityDate.week + 1)[0]);
        }
    }

    const switchToEnd = () => {
        setCharityChallengeData([]);
        setCharityChallengeFullView(false);
        setCurrentCharityDate(charityDays[charityDays.length - 1]);
    }

    const getDailyUSDSum = () => {
        let sum = 0;
        for (let i = 0; i < charityChallengeData.length; i++) {
            sum += charityChallengeData[i].usdValue;
        }
        return Math.round(sum * 1e2)/1e2;
    }

    const getTotalUSDSum = () => {
        let sum = 0;
        for (let i = 0; i < charityChallengeTotalData.length; i++)
            sum += charityChallengeTotalData[i].totalSum;
        return Math.round(sum * 1e2) / 1e2;
    }

    return (
        <>
            {(viewMode == 0 && currentCharityDate) && <div className="challenge-view-mode-description">
                <i className={"fas fa-angles-left challenge-switcher challenge-switcher-begin" + (currentCharityDate.week == 1 ? " inactive" : "")}
                    onClick={() => {
                        if (currentCharityDate.week == 1) return; else switchToBeginning();
                    }}
                />
                <i className={"fas fa-angle-left challenge-switcher challenge-switcher-left" + (currentCharityDate.week == 1 ? " inactive" : "")}
                    onClick={() => {
                        if (currentCharityDate.week == 1) return; else switchToPrev();
                    }}
                />
                <div className="charity-date">{makeDateForCharity(currentCharityDate.charityDate)}<br /><em style={{ fontWeight: 'normal', fontSize: '14px' }}>({currentCharityDate.week}-й период)</em></div>
                <i className={"fas fa-angle-right challenge-switcher challenge-switcher-right" + (currentCharityDate.week == charityDays.length ? " inactive" : "")}
                    onClick={() => {
                        if (currentCharityDate.week == charityDays.length) return; else switchToNext();
                    }}
                />
                <i className={"fas fa-angles-right challenge-switcher challenge-switcher-end" + (currentCharityDate.week == charityDays.length ? " inactive" : "")}
                    onClick={() => {
                        if (currentCharityDate.week == charityDays.length) return; else switchToEnd();
                    }}
                />
            </div>}
            {(viewMode == 1 && charityDays.length > 0) && <div className="challenge-view-mode-description" style={{ fontSize: '16px' }}>Всего на {makeDateForCharity(charityDays[charityDays.length - 1].charityDate)}</div>}
            <div className="challenge-table-container charity-table-container">
                {loadingState && <div className="charity-loader"><img width="200" height="200" src="assets/banners/loader3.gif" /></div>}
                {(viewMode == 0 && !loadingState) && <table className="table table-striped challenge-view-table charity-view-table view-mode-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Резидент</th>
                            <th>Тариф</th>
                            <th>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        {charityChallengeData.map((item, i) => {
                            if ((i < 3 || (i > charityChallengeData.filter(c => c.value > 0).length - 4 && i < charityChallengeData.filter(c => c.value > 0).length)
                                || charityChallengeFullView) && item.account.id != currentUser.id) {
                                return (<tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                    </td>
                                    <td>{getClassSingle(item.account.class)}</td>
                                    <td style={{ fontWeight: 'bold' }}>{item.currency === 'KZT' ? '₸' : item.currency === 'RUB' ? '₽' : ''}{formatCost(item.value)}</td>
                                </tr>)
                            }
                            else if (item.account.id == currentUser.id) {
                                return (<tr key={i}>
                                    <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                    <td style={{ color: '#047ff9' }}>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.account.name} (Я)</div>
                                    </td>
                                    <td style={{ color: '#047ff9' }}>{getClassSingle(item.account.class)}</td>
                                    <td style={{ color: '#047ff9', fontWeight: 'bold' }}>{item.currency === 'KZT' ? '₸' : item.currency === 'RUB' ? '₽' : ''}{formatCost(item.value)}</td>
                                </tr>)
                            }
                            else if (charityChallengeData.findIndex(c => c.account.id == currentUser.id) > -1
                                && charityChallengeData.findIndex(c => c.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={4}>...</td>
                                </tr>)
                            }
                            else if (charityChallengeData.findIndex(c => c.account.id == currentUser.id) > charityChallengeData.filter(c => c.value > 0).length - 4
                                && charityChallengeData.findIndex(c => c.account.id == currentUser.id) < charityChallengeData.filter(c => c.value > 0).length && i == 3) { // me at bottom non-zero result 3: break is one row
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={4}>...</td>
                                </tr>)
                            }
                            else if (charityChallengeData.filter(b => b.account.id == currentUser.id).length > 0
                                && Math.abs(i - charityChallengeData.findIndex(c => c.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={4}>...</td>
                                </tr>)
                            }
                            else if (i == 3 && charityChallengeData.filter(c => c.account.id == currentUser.id).length == 0) {
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={4}>...</td>
                                </tr>)
                            }
                            else {
                                return null;
                            }
                        })}
                    </tbody>
                </table>}
                {(viewMode == 1 && !loadingState) && <table className="table table-striped challenge-view-table charity-view-table view-mode-1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Резидент</th>
                            <th>Тариф</th>
                            <th style={{ color: totalSortColumn === 'cw' ? '#047ff9' : 'initial' }} onClick={() => setTotalSortColumn('cw')}>Ранг*</th>
                            <th style={{ color: totalSortColumn === 'ts' ? '#047ff9' : 'initial' }} onClick={() => setTotalSortColumn('ts')}>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        {charityChallengeTotalData.map((item, i) => {
                            if ((i < 3 || (i > charityChallengeTotalData.filter(c => c.totalSum > 0).length - 4 && i < charityChallengeTotalData.filter(c => c.totalSum > 0).length)
                                || charityChallengeFullView) && item.account.id != currentUser.id) {
                                return (<tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{item.account.name}</span></div>
                                    </td>
                                    <td>{getClassSingle(item.account.class)}</td>
                                    <td onClick={() => setTotalSortColumn('cw')}>
                                        <div className="charity-medal">{item.continuousWeeks}</div>
                                    </td>
                                    <td style={{ fontWeight: 'bold' }} onClick={() => setTotalSortColumn('ts')}>${formatCost(item.totalSum)}</td>
                                </tr>)
                            }
                            else if (item.account.id == currentUser.id) {
                                return (<tr key={i}>
                                    <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                    <td style={{ color: '#047ff9' }}>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{item.account.name} (Я)</div>
                                    </td>
                                    <td style={{ color: '#047ff9' }}>{getClassSingle(item.account.class)}</td>
                                    <td style={{ color: '#047ff9' }} onClick={() => setTotalSortColumn('cw')}>
                                        <div className="charity-medal">{item.continuousWeeks}</div>
                                    </td>
                                    <td style={{ color: '#047ff9', fontWeight: 'bold' }} onClick={() => setTotalSortColumn('ts')}>${formatCost(item.totalSum)}</td>
                                </tr>)
                            }
                            else if (charityChallengeTotalData.findIndex(c => c.account.id == currentUser.id) > -1
                                && charityChallengeTotalData.findIndex(c => c.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={5}>...</td>
                                </tr>)
                            }
                            else if (charityChallengeTotalData.findIndex(c => c.account.id == currentUser.id) > charityChallengeTotalData.filter(c => c.totalSum > 0).length - 4
                                && charityChallengeTotalData.findIndex(c => c.account.id == currentUser.id) < charityChallengeTotalData.filter(c => c.totalSum > 0).length && i == 3) { // me at bottom 3: break is one row
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={5}>...</td>
                                </tr>)
                            }
                            else if (charityChallengeTotalData.filter(c => c.account.id == currentUser.id).length > 0 &&
                                charityChallengeTotalData.filter(c => c.account.id == currentUser.id)[0].totalSum > 0 &&
                                Math.abs(i - charityChallengeTotalData.findIndex(c => c.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={5}>...</td>
                                </tr>)
                            }
                            else if (i == 3 && charityChallengeTotalData.filter(c => c.account.id == currentUser.id).length == 0) {
                                return (<tr key={i} onClick={() => setCharityChallengeFullView(true)}>
                                    <td></td>
                                    <td className="challenge-table-expander" colSpan={5}>...</td>
                                </tr>)
                            }
                            else {
                                return null;
                            }
                        })}
                    </tbody>
                </table>}

            </div>
            {(viewMode == 0 && charityChallengeData.length > 0) && <div className="challenge-charity-total">Итого: <b>₸{formatCost(charityChallengeData.filter(x => x.currency === 'KZT').reduce((accumulator, y) => { return accumulator + y.value }, 0))}</b> + <b>₽{formatCost(charityChallengeData.filter(x => x.currency === 'RUB').reduce((accumulator, y) => { return accumulator + y.value }, 0))}</b></div>}
            {(viewMode == 0 && charityChallengeData.length > 0) && <div className="challenge-charity-total">Итого в долларах США: <b>${formatCost(getDailyUSDSum())}</b></div>}
            {(viewMode == 1 && charityChallengeTotalData.length > 0) && <div className="challenge-charity-total">Итого: <b>${formatCost(getTotalUSDSum())}</b></div>}
            {viewMode == 1 && <div className="challenge-conditions-link">* Ранг — количество недель, в течение которых Резидент непрерывно делает пожертвования по средам. При пропуске одной среды — ранг обнуляется.</div>}
        </>
    )
};

export default CharityChallengeTableView;
