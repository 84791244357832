import * as React from 'react';
import { Account } from '../../store/Models';

interface AccountAvatarProps {
    size: number;
    account: Account;
    showAsAdmin: boolean;
    classArg: number | null;
    onClick: (account: Account) => void;
}

const AccountAvatar: React.FC<AccountAvatarProps> = ({ size, account, showAsAdmin, classArg, onClick }) => {

    const adminId = '1000129';

    const avatarWrapperStyle: React.CSSProperties = {
        'display': 'flex',
        'justifyContent': 'center',
        'marginRight': '1px',
        'marginBottom': '1px'
    }

    const avatarStyle: React.CSSProperties = {
        'width': size + 'px',
        'height': size + 'px',
        'position': 'relative',
        'marginRight': '1px',
        'marginBottom': '1px'
    }

    return (
        <div style={avatarWrapperStyle} className="avatar-upper" onClick={() => { if (account.isAdmin) return; else onClick(account); }}>
            <div style={avatarStyle}>
                <img width={size} height={size} src={'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' +
                    (showAsAdmin ? adminId : account.id)
                } alt="Avatar" className={account.isActive ? "avatar active" : "avatar inactive"}/>
                {classArg != null && <div className={
                    showAsAdmin ? "level admin" :
                        classArg == 5 ? "level exclusive" :
                            classArg == 10 ? "level basic" :
                                classArg == 20 ? "level standard" :
                                    classArg == 25 ? "level stdplus" :
                                        classArg == 30 ? "level bus" :
                                        "level busplus"}>{account.isAdmin > 0 ? "A" : account.level}</div>}
            </div>
        </div>
        )
};

export default AccountAvatar;
