import * as React from 'react';
import { Account, AccountLookUp, Payment } from '../../../../../store/Models';
import { getAvailableSubscriptionsForClass, subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost, getTransactionDescription, makeDateTime } from '../../../../../utils';
import Autocomplete from '../../simple/Autocomplete';
import Datetimepicker from '../../simple/Datetimepicker';
import EvenSaleReward from '../rewards/EvenSaleReward';
import FastStartReward from '../rewards/FastStartReward';
import Approval from './Approval';

interface PaymentApprovalsProps {
    productionMode: boolean;
    onBack: () => void;
}

interface WalletTransaction {
    transactionDateTime: string;
    transactionTypeCode: number;
    initiatorAccount: Account;
    account: Account;
    value: number;
    pointType: string;
}

const PaymentApprovals: React.FC<PaymentApprovalsProps> = ({ productionMode, onBack }) => {

    const [payments, setPayments] = React.useState<Payment[]>([]);
    const [walletTransactions, setWalletTransactions] = React.useState<WalletTransaction[]>([]);

    const [manualMode, setManualMode] = React.useState<boolean>(false);
    const [manualState, setManualState] = React.useState<number>(0);

    const [evenMode, setEvenMode] = React.useState<boolean>(false);

    const [fastStartMode, setFastStartMode] = React.useState<boolean>(false);

    const [adminPanelMode, setAdminPanelMode] = React.useState<number>(0);

    const [accounts, setAccounts] = React.useState<AccountLookUp[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<AccountLookUp | null>(null);
    const [paymentOption, setPaymentOption] = React.useState<string>('');
    const [selectedSubscription, setSelectedSubscription] = React.useState<string>('');
    const [cost, setCost] = React.useState<number>(0);
    const [isDebt, setIsDebt] = React.useState<number>(0);
    const [paymentDateTime, setPaymentDateTime] = React.useState<string>('');
    const [tz, setTz] = React.useState<string>('');

    const [enableCostEdit, setEnableCostEdit] = React.useState<boolean>(false);

    const selectSubscription = (subscriptionCode: string) => {
        //set selected subscription and calculate cost for it
        setSelectedSubscription(subscriptionCode);
        var subscriptionObject = subscriptionsInfo[subscriptionCode];
        if (subscriptionObject != null && selectedAccount != null && paymentOption.length > 0) {
            if (paymentOption === 'Sberbank') {
                setCost(subscriptionObject.rubCost);
            }
            else {
                setCost(subscriptionObject.cost);
            }
        }
    }

    const validate = (): boolean => {
        return selectedAccount != null && selectedSubscription.length > 0 &&
            paymentOption.length > 0 && cost > 0 && paymentDateTime.length > 0 && tz.length > 0;
    }

    const createPayment = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('SubscriptionCode', selectedSubscription);
            formData.append('PaymentMethod', paymentOption);
            formData.append('Cost', cost.toString());
            formData.append('IsDebt', isDebt.toString());
            formData.append('PaymentDateTime', paymentDateTime);
            formData.append('CashboxTz', tz);
            fetch('api/payment/sendPaymentInfoManual', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    setManualState(1);
                })
                .catch(() => {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                });
        }
    }

    const paymentOptions = [
        {
            label: 'Kaspi',
            value: 'Kaspi'
        },
        {
            label: 'Visa/MC',
            value: 'VisaMC'
        },
        {
            label: 'Сбербанк',
            value: 'Sberbank'
        },
        {
            label: 'Наличные',
            value: 'Cash'
        }
    ]

    React.useEffect(() => {
        if (adminPanelMode == 0) {
            fetch('/api/payment/getPayments')
                .then(res => res.json() as Promise<Payment[]>)
                .then((data) => {
                    setPayments(data);
                });
        }
        else if (adminPanelMode == 1) {
            fetch('/api/payment/getWalletTransactions')
                .then(res => res.json() as Promise<WalletTransaction[]>)
                .then((data) => {
                    setWalletTransactions(data);
                });
        }
    }, [adminPanelMode]);

    React.useEffect(() => {
        if (!manualMode && !evenMode && !fastStartMode) {
            fetch('/api/payment/getPayments')
                .then(res => res.json() as Promise<Payment[]>)
                .then((data) => {
                    setPayments(data);
                });
        }
    }, [manualMode, evenMode, fastStartMode]);

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<AccountLookUp[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        if (selectedSubscription.length > 0) {
            selectSubscription(selectedSubscription);
        }
    }, [paymentOption]);

    const dropManualInfo = () => {
        setTz(''); setPaymentDateTime(''); setCost(0); setIsDebt(0);
        setPaymentOption(''); setSelectedSubscription(''); setSelectedAccount(null);
    };

    const [showWalletFilters, setShowWalletFilters] = React.useState<boolean>(false);
    const [walletFilterDateFrom, setWalletFilterDateFrom] = React.useState<string>('');
    const [walletFilterDateTo, setWalletFilterDateTo] = React.useState<string>('');
    const [walletFilterRewardType, setWalletFilterRewardType] = React.useState<string>('');
    const [walletFilterRewardStatus, setWalletFilterRewardStatus] = React.useState<string>('');
    const [walletFilterBeneficiaryAccountId, setWalletFilterBeneficiaryAccountId] = React.useState<string>('');

    React.useEffect(() => {
        var queryStringList = [];
        if (walletFilterDateFrom.length > 0) {
            queryStringList.push('dateFrom=' + walletFilterDateFrom);
        }
        if (walletFilterDateTo.length > 0) {
            queryStringList.push('dateTo=' + walletFilterDateTo);
        }
        if (walletFilterRewardType.length > 0) {
            queryStringList.push('rewardType=' + walletFilterRewardType);
        }
        if (walletFilterRewardStatus.length > 0) {
            queryStringList.push('rewardStatus=' + walletFilterRewardStatus);
        }
        if (walletFilterBeneficiaryAccountId.length > 0) {
            queryStringList.push('beneficiaryAccountID=' + walletFilterBeneficiaryAccountId);
        }

        fetch('/api/payment/getWalletTransactions?' + queryStringList.join('&'))
            .then(res => res.json() as Promise<WalletTransaction[]>)
            .then((data) => {
                setWalletTransactions(data);
            });
    }, [walletFilterDateFrom, walletFilterDateTo, walletFilterRewardType, walletFilterRewardStatus, walletFilterBeneficiaryAccountId]);

    return (
        <div className="payment-approval-container">
            {(!manualMode && !evenMode && !fastStartMode) && <>
                <div className="reg-module-view-header"><b>ПЛАТЕЖИ, ВОЗНАГРАЖДЕНИЯ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <div className="admin-panel-tab">
                    <div className={adminPanelMode == 0 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminPanelMode(0)}>Платёжные операции</div>
                    <div className={adminPanelMode == 1 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminPanelMode(1)}>Вознаграждения</div>
                </div>
                {adminPanelMode == 0 && <div className="payment-approval-table-container">
                    <button className="btn btn-success btn-payment-add-manual" onClick={() => setManualMode(true)}><i className="fas fa-plus" /> Добавить вручную</button>
                    <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <tbody>
                            {payments.map((payment, i) => (
                                <Approval productionMode={productionMode} payment={payment} onModify={setPayments} />
                            ))}
                        </tbody>
                    </table>
                </div>}
                {adminPanelMode == 1 && <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100% - 65px)' }}>
                    <div style={{ marginTop: '5px' }}>
                        <button className="btn btn-primary btn-payment-add-manual" onClick={() => setEvenMode(true)}><i className="fas fa-plus" /> Чётная продажа</button>
                        <button className="btn btn-primary btn-payment-add-manual" onClick={() => setFastStartMode(true)}><i className="fas fa-plus" /> Быстрый старт</button>
                    </div>
                    {!showWalletFilters && <div className="wallet-transactions-filter-switcher">
                        <span onClick={() => setShowWalletFilters(true)}><i className="fas fa-chevron-down" /> Показать фильтры и итоги</span></div>}
                    {showWalletFilters && <div className="wallet-transactions-filter-switcher">
                        <span onClick={() => setShowWalletFilters(false)}><i className="fas fa-chevron-up" /> Скрыть фильтры и итоги</span></div>}
                    {showWalletFilters && <div className="wallet-transactions-filter-panel">
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Дата с</div>
                            <select className="wallet-transactions-filter-select" style={{ marginRight: '10px' }} onChange={(e) => setWalletFilterDateFrom(e.target.value)}>
                                <option value="" selected>Начала</option>
                                <option value="2024-11-18">18.11.2024</option>
                                <option value="2024-11-17">17.11.2024</option>
                                <option value="2024-11-16">16.11.2024</option>
                                <option value="2024-11-15">15.11.2024</option>
                                <option value="2024-11-14">14.11.2024</option>
                                <option value="2024-11-13">13.11.2024</option>
                                <option value="2024-11-12">12.11.2024</option>
                                <option value="2024-11-11">11.11.2024</option>
                                <option value="2024-11-10">10.11.2024</option>
                                <option value="2024-11-09">09.11.2024</option>
                                <option value="2024-11-08">08.11.2024</option>
                                <option value="2024-11-07">07.11.2024</option>
                                <option value="2024-11-06">06.11.2024</option>
                                <option value="2024-11-05">05.11.2024</option>
                                <option value="2024-11-04">04.11.2024</option>
                                <option value="2024-11-03">03.11.2024</option>
                                <option value="2024-11-02">02.11.2024</option>
                                <option value="2024-11-01">01.11.2024</option>
                            </select>
                            <div className="wallet-transactions-filter-label">Дата до</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterDateTo(e.target.value)}>
                                <option value="" selected>Конца</option>
                                <option value="2024-11-18">18.11.2024</option>
                                <option value="2024-11-17">17.11.2024</option>
                                <option value="2024-11-16">16.11.2024</option>
                                <option value="2024-11-15">15.11.2024</option>
                                <option value="2024-11-14">14.11.2024</option>
                                <option value="2024-11-13">13.11.2024</option>
                                <option value="2024-11-12">12.11.2024</option>
                                <option value="2024-11-11">11.11.2024</option>
                                <option value="2024-11-10">10.11.2024</option>
                                <option value="2024-11-09">09.11.2024</option>
                                <option value="2024-11-08">08.11.2024</option>
                                <option value="2024-11-07">07.11.2024</option>
                                <option value="2024-11-06">06.11.2024</option>
                                <option value="2024-11-05">05.11.2024</option>
                                <option value="2024-11-04">04.11.2024</option>
                                <option value="2024-11-03">03.11.2024</option>
                                <option value="2024-11-02">02.11.2024</option>
                                <option value="2024-11-01">01.11.2024</option>
                                <option value="2024-10-31">31.10.2024</option>
                            </select>
                        </div>
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Вид вознаграждения</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterRewardType(e.target.value)}>
                                <option value="" selected>Все виды</option>
                                <option value="1">Личная рекомендация</option>
                                <option value="2">Чётная продажа</option>
                                <option value="3">Быстрый старт</option>
                                <option value="4">Резидуальный (классика)</option>
                            </select>
                        </div>
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Статус</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterRewardStatus(e.target.value)}>
                                <option value="" selected>Все статусы</option>
                                <option value="ecp">Заработано</option>
                                <option value="ncp">Упущено</option>
                            </select>
                        </div>

                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Получатель (куратор)</div>
                            <select className="wallet-transactions-filter-select" style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onChange={(e) => setWalletFilterBeneficiaryAccountId(e.target.value)}>
                                <option value="" selected>Все кураторы</option>
                                {accounts.sort((x, y) => {
                                    var splitX = x.displayString.split('/');
                                    var splitY = y.displayString.split('/');
                                    return splitX[splitX.length - 1] > splitY[splitY.length - 1] ? 1 : -1;
                                }).map(a => (
                                    <option value={a.id}>{a.displayString}</option>
                                    ))}
                            </select>
                        </div>

                        <div className="wallet-transactions-filter-total">Итого:
                            &nbsp;
                            {walletFilterRewardStatus !== 'ncp' && <span style={{ color: 'green' }}>{walletTransactions.filter(x => x.pointType !== 'ncp').reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                            {walletFilterRewardStatus.length == 0 && <span>&nbsp;/&nbsp;</span>}
                            {walletFilterRewardStatus !== 'ecp' && <span style={{ color: 'red' }}>{walletTransactions.filter(x => x.pointType === 'ncp').reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                        </div>
                    </div>}
                    <div style={{ overflowY: 'auto' }}>
                        <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                            <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                                <tr>
                                    <th>Начислено</th>
                                    <th>Вид</th>
                                    <th>Инициатор</th>
                                    <th>Куратор</th>
                                    <th>у.е.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletTransactions.map((w, i) => (
                                    <tr>
                                        <td>{makeDateTime(w.transactionDateTime)}</td>
                                        <td>{getTransactionDescription(w.transactionTypeCode)}</td>
                                        <td>{w.initiatorAccount.humanName}</td>
                                        <td>{w.account.humanName}</td>
                                        <td style={{ fontWeight: 'bold', color: w.pointType === 'ncp' ? 'red' : 'green' }}>{w.value >= 0 && w.pointType !== 'ncp' ? '+' : ''}{w.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </>}
            {(manualMode && manualState == 0) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="btn-payment-add-back" onClick={() => { dropManualInfo(); setManualMode(false); }}><i className="fas fa-chevron-left" /></div>
                <div className="payment-approval-manual-container">
                    <div className="reg-module-block">
                        <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                        <div>
                            {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.displayString, value: a.id.toString() } })}
                                placeholder="Выберите Резидента" customValuesAllowed={false}
                                onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                            {selectedAccount != null && <>
                                <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.displayString}</b></div>
                                <button className="btn btn-primary btn-payment-edit"
                                    onClick={dropManualInfo}>Изм.</button>
                            </>}
                        </div>
                    </div>
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">Способ оплаты</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setPaymentOption(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {paymentOptions.map(po => (
                                    <option value={po.value}>{po.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">За что оплата</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => selectSubscription(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {getAvailableSubscriptionsForClass(selectedAccount.class).map(s => (
                                    <option value={s.code}>{s.displayName}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {paymentOption.length > 0 && <div className="reg-module-block">
                        <div className="reg-module-label">Сумма платежа</div>
                        <div>{paymentOption === 'Sberbank' ? '₽' : '₸'}
                            {enableCostEdit && <input placeholder="Введите сумму платежа" className="reg-module-input" style={{ width: '30%' }}
                                onChange={(e) => setCost(parseInt(e.target.value || '0'))} value={cost} disabled={!enableCostEdit} />}
                            {!enableCostEdit && <div style={{ display: 'inline-block' }}>{formatCost(cost)}</div>}
                            <button className="btn btn-primary btn-payment-edit" onClick={() => setEnableCostEdit(true)}>Изм.</button>
                        </div>
                    </div>}
                    {paymentOption.length > 0 && <div className="reg-module-block">
                        <div className="reg-module-label">В долг</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setIsDebt(parseInt(e.target.value))} defaultValue="0">
                                <option value="0">Нет</option>
                                <option value="1">Да</option>
                            </select>
                        </div>
                    </div>}
                    {selectedAccount != null && <div className="reg-module-block">
                        <div>
                            <Datetimepicker label1="Дата квитанции" label2="Время квитанции" onValueChanged={setPaymentDateTime} />
                        </div>
                    </div>}
                    {selectedAccount != null && <div className="reg-module-block">
                        <div className="reg-module-label">Часовой пояс кассы</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setTz(e.target.value)} defaultValue="">
                                <option style={{ display: 'none' }} value=""></option>
                                <option value="-5">GMT+5 (Астана)</option>
                                <option value="-3">GMT+3 (Москва)</option>
                            </select>
                        </div>
                    </div>}
                    <div className="reg-module-submit">
                        <button className="btn btn-success" disabled={!validate()} onClick={createPayment}>Добавить</button>
                    </div>
                </div>
            </>}
            {(manualMode && manualState == 1 && selectedAccount != null) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="payment-approval-success-container">
                    <div className="payment-approval-success-message">
                        <b>Платёж успешно добавлен!</b>
                        <br />
                        <u>Параметры:</u><br />
                        Резидент: <b>{selectedAccount.displayString}</b><br />
                        Способ оплаты: <b>{paymentOption}</b><br />
                        Подписка: <b>{subscriptionsInfo[selectedSubscription].displayName}</b><br />
                        Сумма: <b>{paymentOption === 'Sberbank' ? '₽' : '₸'}{formatCost(cost)}</b><br />
                        Дата и время платежа в часовом поясе кассы: <b>{paymentDateTime}</b>
                        <br />
                        <br />
                        <div style={{ textDecoration: 'underline' }} onClick={() => {
                            setManualMode(false);
                            setManualState(0);
                            dropManualInfo();
                        }}>Вернуться к списку платёжных операций</div>
                    </div>
                </div>
            </>}
            {evenMode && <EvenSaleReward onBack={() => setEvenMode(false)} accounts={accounts} />}
            {fastStartMode && <FastStartReward onBack={() => setFastStartMode(false)} accounts={accounts} />}
        </div>
    )
};

export default PaymentApprovals;
