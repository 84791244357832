import * as React from 'react';
import { PaymentSession } from '../../../../../store/Models';
import { subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost } from '../../../../../utils';

interface StepperRenewal3Props {
    paymentSession: PaymentSession;
    paymentMethod: string;
    subscriptionCode: string;
    onBack: () => void;
    onNext: () => void;
}

interface PaymentMethodRenewalLink {
    BUSINESS_FROM_STANDARD_PLUS_UPGRADE: string;
    BUSINESS_FROM_STANDARD_UPGRADE: string;
    BUSINESS_FROM_BASIC_UPGRADE: string;
    BUSINESS_FROM_EXCLUSIVE_UPGRADE: string;
    STANDARD_PLUS_FROM_STANDARD_UPGRADE: string;
    STANDARD_PLUS_FROM_BASIC_UPGRADE: string;
    STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE: string;
    STANDARD_FROM_BASIC_UPGRADE: string;
    STANDARD_FROM_EXCLUSIVE_UPGRADE: string;
    BASIC_FROM_EXCLUSIVE_UPGRADE: string;

    BUSINESS_PLUS_RENEWAL: string;
    BUSINESS_RENEWAL: string;
    STANDARD_PLUS_RENEWAL: string;
    STANDARD_RENEWAL: string;
    BASIC_RENEWAL: string;
    EXCLUSIVE_RENEWAL: string;
}

interface ServiceWALink {
    Kaspi: PaymentMethodRenewalLink;
    VisaMC: PaymentMethodRenewalLink;
    Sberbank: PaymentMethodRenewalLink;
}

const StepperRenewal3: React.FC<StepperRenewal3Props> = ({ paymentSession, paymentMethod, subscriptionCode, onBack, onNext }) => {
    const delay = 90;

    const [canFinishPayment, setCanFinishPayment] = React.useState<boolean>(paymentSession.serviceDateTime != null);

    const paymentFinishedRef = React.useRef<boolean>(false);

    const tapServiceLink = () => {
        fetch('/api/payment/tapServiceLink?sessionId=' + paymentSession.id)
            .then(() => {
                window.setTimeout(() => setCanFinishPayment(true), delay * 1000);
            });
    }

    const completePaymentSession = () => {
        if (!paymentFinishedRef.current) {
            paymentFinishedRef.current = true;
            fetch('/api/payment/completePaymentSession?sessionId=' + paymentSession.id)
                .then(onNext);
        }
    }

    const serviceWALinks: ServiceWALink = {
        "Kaspi": {
            "BUSINESS_FROM_STANDARD_PLUS_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_PLUS_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BUSINESS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BUSINESS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_BASIC_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BUSINESS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_EXCLUSIVE_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_STANDARD_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_BASIC_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_FROM_BASIC_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_FROM_EXCLUSIVE_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BASIC_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₸" + formatCost(subscriptionsInfo["BASIC_FROM_EXCLUSIVE_UPGRADE"].cost) + " через Kaspi. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_PLUS_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₸" + formatCost(subscriptionsInfo["BASIC_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₸" + formatCost(subscriptionsInfo["EXCLUSIVE_RENEWAL"].cost) + " через Kaspi. Квитанцию прилагаю."
        },
        "VisaMC": {
            "BUSINESS_FROM_STANDARD_PLUS_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_PLUS_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BUSINESS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BUSINESS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_BASIC_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BUSINESS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_FROM_EXCLUSIVE_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_STANDARD_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_BASIC_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_FROM_BASIC_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_FROM_EXCLUSIVE_UPGRADE"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BASIC_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₸" + formatCost(subscriptionsInfo["BASIC_FROM_EXCLUSIVE_UPGRADE"].cost) + " через картой Visa/MC. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_PLUS_RENEWAL"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₸" + formatCost(subscriptionsInfo["BUSINESS_RENEWAL"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт Плюс» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_PLUS_RENEWAL"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₸" + formatCost(subscriptionsInfo["STANDARD_RENEWAL"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₸" + formatCost(subscriptionsInfo["BASIC_RENEWAL"].cost) + " картой Visa/MC. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₸" + formatCost(subscriptionsInfo["EXCLUSIVE_RENEWAL"].cost)+" картой Visa/MC. Квитанцию прилагаю."
        },
        "Sberbank": {
            "BUSINESS_FROM_STANDARD_PLUS_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_PLUS_UPGRADE"].rubCost) +" переводом на Сбербанк России. Квитанцию прилагаю.",
            "BUSINESS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_FROM_STANDARD_UPGRADE"].rubCost) +" переводом на Сбербанк России. Квитанцию прилагаю.",
            "BUSINESS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_FROM_BASIC_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "BUSINESS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_FROM_EXCLUSIVE_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_STANDARD_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_STANDARD_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_BASIC_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт Плюс» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_FROM_BASIC_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_FROM_BASIC_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_FROM_EXCLUSIVE_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "BASIC_FROM_EXCLUSIVE_UPGRADE": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₽" + formatCost(subscriptionsInfo["BASIC_FROM_EXCLUSIVE_UPGRADE"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_PLUS_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₽" + formatCost(subscriptionsInfo["BUSINESS_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт Плюс» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_PLUS_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₽" + formatCost(subscriptionsInfo["STANDARD_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₽" + formatCost(subscriptionsInfo["BASIC_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₽" + formatCost(subscriptionsInfo["EXCLUSIVE_RENEWAL"].rubCost) + " переводом на Сбербанк России. Квитанцию прилагаю."
        }
    }

    const getServiceWALink = (paymentMethod: string, subscriptionCode: string): string => {
        return "https://wa.me/77052042585?text=" + serviceWALinks[paymentMethod as keyof ServiceWALink][subscriptionCode as keyof PaymentMethodRenewalLink];
    }

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-info-text">Отправьте квитанцию</div>
                <div>в <a target="_blank" href={getServiceWALink(paymentMethod, subscriptionCode)} onClick={tapServiceLink}>Службу Заботы</a></div>
            </div>
            <div className="stepper-button-container">
                <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                <button className="stepper-button" onClick={completePaymentSession} disabled={!canFinishPayment}>
                    Готово
                </button>
            </div>
        </React.Fragment>
    )
};

export default StepperRenewal3;
