import * as React from 'react';
import { PaymentSession } from '../../../../../store/Models';
import { CostInfo, getAvailableSubscriptionsForClass, subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost } from '../../../../../utils';

interface Stepper1Props {
    paymentSession: PaymentSession;
    onBack: () => void;
    onNext: (selectedOption: CostInfo|null) => void;
}

const Stepper1: React.FC<Stepper1Props> = ({ paymentSession, onBack, onNext }) => {
    const handleSubscriptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = subscriptionsInfo[selectedValue];
        setSelectedSubscription(selected || null);
    };

    const [selectedSubscription, setSelectedSubscription] = React.useState<CostInfo | null>(null);

    const onSelectSubscription = () => {
        if (selectedSubscription != null) {
            fetch('/api/payment/selectSubscription?sessionId=' + paymentSession.id
                + '&subscriptionCode=' + selectedSubscription.code)
                .then(() => {
                    onNext(selectedSubscription);
                });
        }
    }

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-select-caption">Выберите тариф</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" onChange={handleSubscriptionSelect} defaultValue="">
                        <option value="">Выберите тариф</option>
                        {getAvailableSubscriptionsForClass(-1).map((option) => (
                            <option key={option.code} value={option.code}>
                                {option.displayName} (₸{formatCost(option.cost)})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stepper-info-text">
                    Сумма: {selectedSubscription ? "₸" + formatCost(selectedSubscription.cost) : '—'}
                </div>
            </div>
            <div className="stepper-button-container">
                <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                <button className="stepper-button" onClick={onSelectSubscription} disabled={!selectedSubscription}>
                    К оплате
                </button>
            </div>
        </React.Fragment>
    )
};

export default Stepper1;
