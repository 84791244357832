import * as React from 'react';
import Autocomplete from '../../simple/Autocomplete';
import Countdown from '../../simple/Countdown';
import Datepicker from '../../simple/Datepicker';
import StepperComponent from '../stepper/Stepper';
import Reg0 from './Reg0';
import Reg1 from './Reg1';
import Reg2 from './Reg2';
import Reg3 from './Reg3';
import { Account } from '../../../../../store/Models';
import RegDeny from './RegDeny';

interface RegModuleProps {
    productionMode: boolean;
    state: string;
    visible: boolean;
    referrerID: number;
    companyEventID: number;
    onReg0: () => void;
    onFinishReg: () => void;
}


const RegModule: React.FC<RegModuleProps> = ({ productionMode, state, visible, referrerID, companyEventID, onReg0, onFinishReg }) => {

    const regModuleRef = React.useRef<HTMLDivElement>(null);

    const regModuleState = {
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        Deny: 'DENY'
    };

    const [reg0finishedState, setReg0finishedState] = React.useState<boolean>(false);

    const [reg2Manual, setReg2Manual] = React.useState<boolean>(false);

    const [paymentRegistered, setPaymentRegistered] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (regModuleRef.current != null) {
            if (visible) {
                regModuleRef.current.style.display = '';
            }
            else {
                regModuleRef.current.style.display = 'none';
            }
        }
    }, [visible]);


    /*
     * (state === regModuleState.Reg0 && !reg0finishedState)
     * 
     * 
     * <div className="reg-module-view-payment-description">
                    <p>Пройдя по ссылке Вам нужно заполнить следующие поля:</p>
                    <p>&nbsp;&nbsp;1. Город и Название компании — НЕ МЕНЯЕМ!</p>
                    <p>&nbsp;&nbsp;2. ФИО учащегося — заполнить Фамилию Имя Отчество Участника нашего мероприятия, за которого делается оплата</p>
                    <p>&nbsp;&nbsp;3. Номер телефона — номер телефона Участника нашего мероприятия</p>
                    <p>&nbsp;&nbsp;❗️Вводить 11 цифр, начиная с цифры «8» или «7», без «+», скобок, пробелов и других символов.</p>
                    <p>&nbsp;&nbsp;4. Назначение платежа — Клуб MillionERA</p>
                    <p>&nbsp;&nbsp;5. Пакет/Категория билета — в зависимости от выбранного тарифа</p>
                    <p>&nbsp;&nbsp;6. Город участия — свой город.</p>
                    <p>&nbsp;&nbsp;7. Сумма платежа — в зависимости от выбранного тарифа</p>
                </div>
     */

    /*
     * {((state === regModuleState.Reg2 || reg2Manual) && !paymentRegistered) &&
                <PaymentModule mode="INITIAL" onPaymentRegistered={() => setPaymentRegistered(true)} />}
     * 
     */

    const proceedToPayment = () => {
        fetch('/api/account/proceedToPayment')
            .then(() => {
                setReg2Manual(true);
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            })
    }

    return (
        <div ref={regModuleRef} style={{ 'height': '100%', display: 'none' }}>
            <div className="reg-module-view-header"><b>РЕГИСТРАЦИЯ</b></div>

            <Reg0 visible={state === regModuleState.Reg0 && !reg0finishedState} referrerID={referrerID} companyEventID={companyEventID} onFinish={() => setReg0finishedState(true)} />

            {(state === regModuleState.Reg0 && reg0finishedState && !reg2Manual) &&
                <Reg1 firstEntry={true} canProceedToPayment={companyEventID == 0} onViewFormData={() => setReg0finishedState(false)} onProceedToPayment={proceedToPayment} />}

            {(state === regModuleState.Reg1 && !reg2Manual) &&
                <Reg1 firstEntry={false} canProceedToPayment={companyEventID == 0} onViewFormData={() => { setReg0finishedState(false); onReg0(); }} onProceedToPayment={() => setReg2Manual(true)} />}

            {((state === regModuleState.Reg2 || reg2Manual) && !paymentRegistered) && <Reg2 productionMode={productionMode} onBack={() => setReg2Manual(false)} onFinishPayment={() => setPaymentRegistered(true)} />}

            {paymentRegistered && <Reg3 onFinishReg={onFinishReg} />}

            {state === regModuleState.Deny && <RegDeny />}
        </div>
    )
};

export default RegModule;