import React from 'react';

interface DateTimePickerProps {
    label1: string;
    label2: string;
    defaultValue?: string;
    onValueChanged: (value: string) => void;
}

const Datetimepicker: React.FC<DateTimePickerProps> = ({ label1, label2, defaultValue, onValueChanged }) => {

    var defaultDateTime = defaultValue != null ? new Date(defaultValue) : null;
    if (defaultDateTime != null) {
        var deltaHours = defaultDateTime.getTimezoneOffset() / 60;
        defaultDateTime.setHours(defaultDateTime.getHours() - deltaHours);
    }

    const [dayValue, setDayValue] = React.useState<string>(defaultDateTime != null ? defaultDateTime.getDate().toString() : new Date().getDate().toString());
    const [monthValue, setMonthValue] = React.useState<string>(defaultDateTime != null ? (defaultDateTime.getMonth() + 1).toString() : (new Date().getMonth() + 1).toString());
    const [yearValue, setYearValue] = React.useState<string>(defaultDateTime != null ? defaultDateTime.getFullYear().toString() : new Date().getFullYear().toString());

    const [hourValue, setHourValue] = React.useState<string>(defaultDateTime != null ? defaultDateTime.getHours().toString() : "0");
    const [minuteValue, setMinuteValue] = React.useState<string>(defaultDateTime != null ? defaultDateTime.getMinutes().toString() : "0");
    const [secondValue, setSecondValue] = React.useState<string>(defaultDateTime != null ? defaultDateTime.getSeconds().toString() : "0");

    const days = [];
    for (var i = 0; i < 31; i++) {
        days.push(i + 1);
    }

    const years = [];
    const now = new Date();
    for (var i = now.getFullYear(); i >= now.getFullYear() - 2; i--) {
        years.push(i);
    }

    const hours = [];
    for (var i = 0; i < 24; i++) {
        hours.push(i);
    }

    const minutes = [];
    for (var i = 0; i < 60; i++) {
        minutes.push(i);
    }

    const seconds = [];
    for (var i = 0; i < 60; i++) {
        seconds.push(i);
    }


    const onDaySelect = (value: string) => {
        setDayValue(value);
        if (monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + value + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onMonthSelect = (value: string) => {
        setMonthValue(value);
        if (dayValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + value + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onYearSelect = (value: string) => {
        setYearValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(value + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onHourSelect = (value: string) => {
        setHourValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && minuteValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + value + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onMinuteSelect = (value: string) => {
        setMinuteValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && secondValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + value.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }
    }

    const onSecondSelect = (value: string) => {
        setSecondValue(value);
        if (dayValue.length > 0 && monthValue.length > 0 && yearValue.length > 0 && hourValue.length > 0 && minuteValue.length > 0) {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + value.padStart(2, '0'));
        }
    }

    React.useEffect(() => {
        window.setTimeout(() => {
            onValueChanged(yearValue + '-' + monthValue + '-' + dayValue + ' ' + hourValue + ':' + minuteValue.padStart(2, '0') + ':' + secondValue.padStart(2, '0'));
        }, 0);
    }, []);

    return (
        <>
            <div className="reg-module-label">{label1}</div>
            <div className="reg-module-datetimepicker">
                <select className="reg-module-datetimepicker-day" onChange={(e) => onDaySelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getDate() : new Date().getDate()}>
                    {days.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>
                <select className="reg-module-datetimepicker-month" onChange={(e) => onMonthSelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getMonth() + 1 : new Date().getMonth() + 1}>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <select className="reg-module-datetimepicker-year" onChange={(e) => onYearSelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getFullYear() : new Date().getFullYear()}>
                    {years.map(i => <option value={i}>{i}</option>)}
                </select>
            </div>
            <div className="reg-module-label">{label2}</div>
            <div className="reg-module-datetimepicker">
                <select className="reg-module-datetimepicker-hours" onChange={(e) => onHourSelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getHours() : 0}>
                    {hours.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;ч.
                <select className="reg-module-datetimepicker-minutes" onChange={(e) => onMinuteSelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getMinutes() : 0}>
                    {minutes.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;мин.
                <select className="reg-module-datetimepicker-seconds" onChange={(e) => onSecondSelect(e.target.value)} defaultValue={defaultDateTime != null ? defaultDateTime.getSeconds() : 0}>
                    {seconds.map(i => <option value={i}>{i.toString().padStart(2, '0')}</option>)}
                </select>&nbsp;сек.
            </div>
        </>
    );
};

export default Datetimepicker;